<template>
  <div id="app" class="m-4 p-4 bg-white rounded">
    <ul class="list-disc">
      <li>
        <a href="share.html#/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjE3NzAxMzUyMTM1IiwiZXhwIjoxNzE0Mjc1MzM2LCJpYXQiOjE2NTEyMDMzMzZ9.z5Lj1KVlu5DIc1lBdITm6odsPk-W6jGy0lau2sYG1Ug"
           class="link" target="_blank"
        >分享与推广</a
        >
      </li>
      <li><a href="guide.html" target="_blank">网址导航</a></li>
      <li><a href="help.html" target="_blank">帮助</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
};
</script>

<style src="../../assets/tailwind.css"></style>
